import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful 
 * when you want to change your logo depending on the theme you are on. 
 */
export default function Logo({ fill }) {
  return (
<svg viewBox="0 0 60 30" height="60px">
<path d="M5.33,13.6a3,3,0,0,0-.68-1.29v0H6.32v0a1.72,1.72,0,0,0,0,1.33l1,3.24,1.24-3.12-.07-.16a3.19,3.19,0,0,0-.68-1.29v0H9.51v0a1.66,1.66,0,0,0,0,1.33l1.12,3.17L11.52,14a2,2,0,0,0,0-1.72v0h1.06v0a3.18,3.18,0,0,0-.78,1.45l-1.45,4.45h-.16L8.66,14,7,18.21H6.84Z"/>
<path d="M14.1,16.7v-3a2.53,2.53,0,0,0-.32-1.43v0h1.56v0A2.53,2.53,0,0,0,15,13.74v3a2.53,2.53,0,0,0,.32,1.43v0H13.78v0A2.53,2.53,0,0,0,14.1,16.7Z"/>
<path d="M17.44,16.56A2,2,0,0,0,18,18.13v0H16.76v0a2.17,2.17,0,0,0,.48-1.57V13.42a1.3,1.3,0,0,0-.62-1.11v0H17.9l3.35,4.55,0-3a2.27,2.27,0,0,0-.49-1.57v0h1.15v0a2.29,2.29,0,0,0-.49,1.57v4.27l-.29,0-3.71-5Z"/>
<path d="M23.92,18.27a2.56,2.56,0,0,1-.93-.18l-.15-1.32.07,0c.55.89.8,1.36,1.09,1.36s.36-.23.36-.62V13.74A2.53,2.53,0,0,0,24,12.31v0h1.55v0a2.53,2.53,0,0,0-.32,1.43V17A1.18,1.18,0,0,1,23.92,18.27Z"/>
<path d="M27.43,16.7v-3a2.53,2.53,0,0,0-.32-1.43v0h1.56v0a2.45,2.45,0,0,0-.32,1.43v3a2.45,2.45,0,0,0,.32,1.43v0H27.11v0A2.53,2.53,0,0,0,27.43,16.7Z"/>
<path d="M30.76,16.56a2.06,2.06,0,0,0,.55,1.57v0H30.08v0a2.18,2.18,0,0,0,.49-1.57V13.42A1.3,1.3,0,0,0,30,12.31v0h1.28l3.35,4.55,0-3a2.27,2.27,0,0,0-.48-1.57v0h1.15v0a2.24,2.24,0,0,0-.49,1.57v4.27l-.29,0-3.71-5Z"/>
<path d="M39.56,18.27a3,3,0,0,1-3.11-3,3,3,0,0,1,3.25-3.05,6,6,0,0,1,2,.39l.16,1.3-.07,0c-.76-1.1-1.35-1.55-2.21-1.55-1.47,0-2.15,1.27-2.15,2.9s.6,2.88,2.09,2.88c.87,0,1.47-.44,1.47-1.28a2.17,2.17,0,0,0-.37-1.32v0h1.57v0a2.51,2.51,0,0,0-.32,1.43v.48A3.73,3.73,0,0,1,39.56,18.27Z"/>
<path d="M44.47,18.27a2.56,2.56,0,0,1-.93-.18l-.15-1.32.07,0c.55.89.8,1.36,1.09,1.36s.36-.23.36-.62V13.74a2.53,2.53,0,0,0-.32-1.43v0h1.55v0a2.53,2.53,0,0,0-.31,1.43V17A1.19,1.19,0,0,1,44.47,18.27Z"/>
<path d="M48,16.7v-3a2.53,2.53,0,0,0-.32-1.43v0h1.56v0a2.45,2.45,0,0,0-.32,1.43v3a2.45,2.45,0,0,0,.32,1.43v0H47.66v0A2.53,2.53,0,0,0,48,16.7Z"/>
<path d="M50.8,18.15v0a3,3,0,0,0,.31-1.33V13.65a3,3,0,0,0-.31-1.34v0h3.7l.31,1.43-.07,0c-.42-.45-1-1.25-1.35-1.27L52,12.41v2.71h.69c.39,0,.71-.54,1-.84h.07v1.8h-.07c-.26-.28-.61-.8-1-.8H52V18L53.46,18c.66,0,1.28-.81,1.72-1.43l.07,0-.35,1.59Z"/>
</svg>
  );
}

